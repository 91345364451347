import "./PR.scss";
import "./PR.adaptive.scss";
import { imagesProvider } from "../../../imagesProvider/imagesProvider";
import { prBrandData } from "./shared/prTypes";
import { PageEnum } from "../../../common/types";
import { useTranslation } from "react-i18next";

const prBrandsList: prBrandData[][] = [
  [
    { identificator: "1", logoImg: imagesProvider.prMarketing.prLogo1 },
    { identificator: "2", logoImg: imagesProvider.prMarketing.prLogo2 },
  ],
  [
    { identificator: "3", logoImg: imagesProvider.prMarketing.prLogo3 },
    { identificator: "4", logoImg: imagesProvider.prMarketing.prLogo4 },
    { identificator: "5", logoImg: imagesProvider.prMarketing.prLogo5 },
  ],
  [
    { identificator: "6", logoImg: imagesProvider.prMarketing.prLogo6 },
    { identificator: "7", logoImg: imagesProvider.prMarketing.prLogo7 },
  ],
  [
    { identificator: "8", logoImg: imagesProvider.prMarketing.prLogo8 },
    { identificator: "9", logoImg: imagesProvider.prMarketing.prLogo9 },
    { identificator: "10", logoImg: imagesProvider.prMarketing.prLogo10 },
  ],
];

const PR = () => {
  const { t } = useTranslation();

  return (
    <div id={PageEnum.pr} className="il--pr">
      <div className="il--pr--inner">
        <div className="il--pr--left-wrapper">
          <div className="il--pr--title">{t("PRInfluenceMarketing.title")}</div>
          <div className="il--pr--text">
            {t("PRInfluenceMarketing.subTitle")}
          </div>
        </div>
        <div className="il--pr--right-wrapper">
          {prBrandsList.map((row, i) => (
            <div key={i} className="il--pr--brands-row">
              {row.map((cell, j) => (
                <div key={j} className="il--pr--brands-cell il--blur-card">
                  <img
                    className="il--partner-slider--logo-img "
                    src={cell.logoImg}
                    alt={`logo-${j}`}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PR;
