const ListingPoolPlan = (t: any) => {
  return (
    <div>
      <p>{t("services.section2.content3.text1")}</p>
      <p>{t("services.section2.content3.text2")}</p>

      <span>{t("services.section2.content3.text3")}</span>
      <ul>
        <li>{t("services.section2.content3.text4")}</li>
        <li>{t("services.section2.content3.text5")}</li>
        <li>{t("services.section2.content3.text6")}</li>
        <li>{t("services.section2.content3.text7")}</li>
      </ul>

      <p><b>{t("services.section2.content3.text8")}</b></p>
      <p>{t("services.section2.content3.text9")}</p>

      <p><b>{t("services.section2.content3.text10")}</b></p>
      <p>{t("services.section2.content3.text11")}</p>

      <p><b>{t("services.section2.content3.text12")}</b></p>
      <p>{t("services.section2.content3.text13")}</p>

      <p>{t("services.section2.content3.text14")}</p>

      <span>{t("services.section2.content3.text15")}</span>
      <ul>
        <li>{t("services.section2.content3.text16")}</li>
        <li>{t("services.section2.content3.text17")}</li>
        <li>{t("services.section2.content3.text18")}</li>
        <li>{t("services.section2.content3.text19")}</li>
        <li>{t("services.section2.content3.text20")}</li>
        <li>{t("services.section2.content3.text21")}</li>
      </ul>

      

    </div>
  );
};

export default ListingPoolPlan;
