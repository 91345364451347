import "./HeaderLanguageSelector.scss";
import "./HeaderLanguageSelector.adaptive.scss";
import LanguageButton from "../../../../components/languageButton/LanguageButton";
import { imagesProvider } from "../../../../imagesProvider/imagesProvider";
import { Language } from "../../../../common/types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const HeaderLanguageSelector = () => {
  const { t, i18n } = useTranslation();

  const [menuShown, setMenuShown] = useState(false);

  const onChangeLanguageHandler = (language: Language): void => {
    setMenuShown(false);
    i18n.changeLanguage(language);
  };

  return (
    <div className="il--header-language-selector">
      <LanguageButton
        iconPackage={imagesProvider.icons.arrowDown}
        onClick={() => setMenuShown(!menuShown)}
      />
      {menuShown ? (
        <div className="il--header-language-selector-menu il--blur-card">
          <div onClick={() => onChangeLanguageHandler("en")}>English</div>
          <div onClick={() => onChangeLanguageHandler("de")}>Deutch</div>
          <div onClick={() => onChangeLanguageHandler("ru")}>Russian</div>
          <div onClick={() => onChangeLanguageHandler("ua")}>Ukraine</div>
        </div>
      ) : null}
    </div>
  );
};

export default HeaderLanguageSelector;
