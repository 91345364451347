import "./IconButton.scss";
import "./IconButton.adaptive.scss";

import { IconPackage, IconStateEnum } from "../../imagesProvider/icons/icons";
import Icon from "../icon/Icon";

interface IProps {
  iconPackage: IconPackage;
  useOnTouch?: boolean;
  onClick?: () => void;
}

const IconButton = ({ iconPackage, onClick }: IProps) => {
  return (
    <div className="il--icon-button" onClick={onClick}>
      <div>
        <div className="il--icon-button--icon-main">
          <Icon
            width={18}
            height={18}
            iconPackage={iconPackage}
            iconState={IconStateEnum.Main}
          />
        </div>
        <div className="il--icon-button--icon-hover">
          <Icon
            width={24}
            height={24}
            iconPackage={iconPackage}
            iconState={IconStateEnum.Hover}
          />
        </div>
        <div className="il--icon-button--icon-press">
          <Icon
            width={24}
            height={24}
            iconPackage={iconPackage}
            iconState={IconStateEnum.Press}
          />
        </div>
      </div>
    </div>
  );
};

export default IconButton;
