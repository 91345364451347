import ArrowRightDownMainSvg from '../../assets/images/svg/common/arrow-right-down_main.svg'
import ArrowRightDownHoverSvg from '../../assets/images/svg/common/arrow-right-down_hover.svg'
import ArrowRightUpMainSvg from '../../assets/images/svg/common/arrow-right-up_main.svg'
import ArrowRightUpActiveSvg from '../../assets/images/svg/common/arrow-right-up_active.svg'

import ArrowDownMainSvg from '../../assets/images/svg/common/arrow-down_main.svg'
import ArrowDownHoverSvg from '../../assets/images/svg/common/arrow-down_hover.svg'

import ArrowRightMainSvg from '../../assets/images/svg/common/arrow-right_main.svg'

import ArrowLeftMainSvg from '../../assets/images/svg/common/arrow-left_main.svg'


import CrossMainSvg from '../../assets/images/svg/common/cross_main.svg'
import ServicePyramidePng from '../../assets/images/png/service-pyramide_main.png'
import ServiceProductDocsMainSvg from '../../assets/images/svg/services/service-product-docs_main.svg'
import ServiceProductDocsActiveSvg from '../../assets/images/svg/services/service-product-docs_active.svg'

import ServiceAdvisingMainSvg from '../../assets/images/svg/services/service-advising_main.svg'
import ServiceAdvisingActiveSvg from '../../assets/images/svg/services/service-advising_active.svg'

import ServiceBlockchainMainSvg from '../../assets/images/svg/services/service-blockchain_main.svg'
import ServiceBlockchainActiveSvg from '../../assets/images/svg/services/service-blockchain_active.svg'

import ServiceBlockchainDocsMainSvg from '../../assets/images/svg/services/service-blockchaindocs_main.svg'
import ServiceBlockchainDocsActiveSvg from '../../assets/images/svg/services/service-blockchaindocs_active.svg'

import ServiceLegalMainSvg from '../../assets/images/svg/services/service-legal_main.svg'
import ServiceLegalActiveSvg from '../../assets/images/svg/services/service-legal_active.svg'

import ServiceListingsMainSvg from '../../assets/images/svg/services/service-listings_main.svg'
import ServiceListingsActiveSvg from '../../assets/images/svg/services/service-listings_active.svg'

import ServiceMarketStrategyMainSvg from '../../assets/images/svg/services/service-market-strategy_main.svg'
import ServiceMarketStrategyActiveSvg from '../../assets/images/svg/services/service-market-strategy_active.svg'

import ServiceMMMainSvg from '../../assets/images/svg/services/service-mm_main.svg'
import ServiceMMActiveSvg from '../../assets/images/svg/services/service-mm_active.svg'

import ServicePRMainSvg from '../../assets/images/svg/services/service-pr_main.svg'
import ServicePRActiveSvg from '../../assets/images/svg/services/service-pr_active.svg'

import ServicePR2MainSvg from '../../assets/images/svg/services/service-pr-2_main.svg'
import ServicePR2ActiveSvg from '../../assets/images/svg/services/service-pr-2_active.svg'

import ServiceSmmMainSvg from '../../assets/images/svg/services/service-smm_main.svg'
import ServiceSmmActiveSvg from '../../assets/images/svg/services/service-smm_active.svg'

import ServiceTokensaleMainSvg from '../../assets/images/svg/services/service-tokensale_main.svg'
import ServiceTokensaleActiveSvg from '../../assets/images/svg/services/service-tokensale_active.svg'

import TelegramMainSvg from '../../assets/images/svg/common/telegram_main.svg'
import WhatsupMainSvg from '../../assets/images/svg/common/whatsup_main.svg'
import PhoneSvg from '../../assets/images/svg/common/phone.svg'

import HamburgerMainSvg from '../../assets/images/svg/common/hamburger_main.svg'

import Partner1 from '../../assets/images/svg/partners/partner_1.svg'
import Partner2 from '../../assets/images/svg/partners/partner_2.svg'
import Partner3 from '../../assets/images/svg/partners/partner_3.svg'
import Partner4 from '../../assets/images/svg/partners/partner_4.svg'
import Partner5 from '../../assets/images/svg/partners/partner_5.svg'
import Partner6 from '../../assets/images/svg/partners/partner_6.svg'
import Partner7 from '../../assets/images/svg/partners/partner_7.svg'
import Partner8 from '../../assets/images/svg/partners/partner_8.svg'
import Partner9 from '../../assets/images/svg/partners/partner_9.svg'
import Partner10 from '../../assets/images/svg/partners/partner_10.svg'
import Partner11 from '../../assets/images/svg/partners/partner_11.svg'
import Partner12 from '../../assets/images/svg/partners/partner_12.svg'



export enum IconStateEnum {
    Main = 'Main',
    Hover = 'Hover',
    Press = 'Press',
    Active = 'Active',
}

export interface IconPackage {
    main: string,
    hover?: string,
    press?: string,
    active?: string,
}

export interface Icons {
    arrowRightDown: IconPackage
    arrowRightUp: IconPackage
    arrowDown: IconPackage
    arrowRight: IconPackage
    arrowLeft: IconPackage
    cross: IconPackage
    servicePyramide: IconPackage
    serviceProductDocs: IconPackage
    serviceAdvising: IconPackage
    serviceBlockchain: IconPackage
    serviceBlockchainDocs: IconPackage
    serviceLegal: IconPackage
    serviceListings: IconPackage
    serviceMarketStrategy: IconPackage
    serviceMM: IconPackage
    servicePR: IconPackage
    servicePR2: IconPackage
    serviceSmm: IconPackage
    serviceTokensale: IconPackage
    telegram: IconPackage
    whatsup: IconPackage
    phone: IconPackage
    hamburger: IconPackage
    partner1: IconPackage
    partner2: IconPackage
    partner3: IconPackage
    partner4: IconPackage
    partner5: IconPackage
    partner6: IconPackage
    partner7: IconPackage
    partner8: IconPackage
    partner9: IconPackage
    partner10: IconPackage
    partner11: IconPackage
    partner12: IconPackage
}

export const icons: Icons = {
    arrowRightDown: {
        main: ArrowRightDownMainSvg,
        hover: ArrowRightDownHoverSvg,
    },
    arrowRightUp: {
        main: ArrowRightUpMainSvg,
        active: ArrowRightUpActiveSvg,
    },
    arrowDown: {
        main: ArrowDownMainSvg,
        hover: ArrowDownHoverSvg,
    },
    arrowRight: {
        main: ArrowRightMainSvg,
    },
    arrowLeft: {
        main: ArrowLeftMainSvg,
    },
    cross: {
        main: CrossMainSvg,
    },
    servicePyramide: {
        main: ServicePyramidePng
    },
    serviceProductDocs: {
        main: ServiceProductDocsMainSvg,
        active: ServiceProductDocsActiveSvg,
    },
    serviceAdvising: {
        main: ServiceAdvisingMainSvg,
        active: ServiceAdvisingActiveSvg,
    },
    serviceBlockchain: {
        main: ServiceBlockchainMainSvg,
        active: ServiceBlockchainActiveSvg,
    },
    serviceBlockchainDocs: {
        main: ServiceBlockchainDocsMainSvg,
        active: ServiceBlockchainDocsActiveSvg,
    },
    serviceLegal: {
        main: ServiceLegalMainSvg,
        active: ServiceLegalActiveSvg,
    },
    serviceListings: {
        main: ServiceListingsMainSvg,
        active: ServiceListingsActiveSvg,
    },
    serviceMarketStrategy: {
        main: ServiceMarketStrategyMainSvg,
        active: ServiceMarketStrategyActiveSvg,
    },
    serviceMM: {
        main: ServiceMMMainSvg,
        active: ServiceMMActiveSvg,
    },
    servicePR: {
        main: ServicePRMainSvg,
        active: ServicePRActiveSvg,
    },
    servicePR2: {
        main: ServicePR2MainSvg,
        active: ServicePR2ActiveSvg,
    },
    serviceSmm: {
        main: ServiceSmmMainSvg,
        active: ServiceSmmActiveSvg,
    },
    serviceTokensale: {
        main: ServiceTokensaleMainSvg,
        active: ServiceTokensaleActiveSvg,
    },
    telegram: {
        main: TelegramMainSvg,
    },
    whatsup: {
        main: WhatsupMainSvg,
    },
    phone: {
        main: PhoneSvg,
    },
    hamburger: {
        main: HamburgerMainSvg,
    },
    partner1: {
        main: Partner1,
    },
    partner2: {
        main: Partner2,
    },
    partner3: {
        main: Partner3,
    },
    partner4: {
        main: Partner4,
    },
    partner5: {
        main: Partner5,
    },
    partner6: {
        main: Partner6,
    },
    partner7: {
        main: Partner7,
    },
    partner8: {
        main: Partner8,
    },
    partner9: {
        main: Partner9,
    },
    partner10: {
        main: Partner10,
    },
    partner11: {
        main: Partner11,
    },
    partner12: {
        main: Partner12,
    },
}
