import PrLogo1 from '../../assets/images/svg/pr_marketing/pr_1.svg'
import PrLogo2 from '../../assets/images/svg/pr_marketing/pr_2.svg'
import PrLogo3 from '../../assets/images/svg/pr_marketing/pr_3.svg'
import PrLogo4 from '../../assets/images/svg/pr_marketing/pr_4.svg'
import PrLogo5 from '../../assets/images/svg/pr_marketing/pr_5.svg'
import PrLogo6 from '../../assets/images/svg/pr_marketing/pr_6.svg'
import PrLogo7 from '../../assets/images/svg/pr_marketing/pr_7.svg'
import PrLogo8 from '../../assets/images/svg/pr_marketing/pr_8.svg'
import PrLogo9 from '../../assets/images/svg/pr_marketing/pr_9.svg'
import PrLogo10 from '../../assets/images/svg/pr_marketing/pr_10.svg'

export const prMarketing  = {
    prLogo1: PrLogo1,
    prLogo2: PrLogo2,
    prLogo3: PrLogo3,
    prLogo4: PrLogo4,
    prLogo5: PrLogo5,
    prLogo6: PrLogo6,
    prLogo7: PrLogo7,
    prLogo8: PrLogo8,
    prLogo9: PrLogo9,
    prLogo10: PrLogo10,
}