const B2Bpartnerships = (t: any) => {
  return (
    <div>
      <p>{t("services.section11.content3.text1")}</p>
      <p>{t("services.section11.content3.text2")}</p>
    </div>
  );
};

export default B2Bpartnerships;
