import { Page, PageEnum } from './types'

export const PAGES_DATA: Record<PageEnum, Page> = {
    [PageEnum.main]: {
        path: '/',
    },
    [PageEnum.home]: {
        path: '/',
    },
    [PageEnum.landing]: {
        path: 'landing',
    },
    [PageEnum.services]: {
        path: 'services',
    },
    [PageEnum.products]: {
        path: 'products',
    },
    [PageEnum.productsV2]: {
        path: 'products-v2',
    },
    [PageEnum.partners]: {
        path: 'partners',
    },
    [PageEnum.events]: {
        path: 'events',
    },
    [PageEnum.pr]: {
        path: 'pr',
    },
    [PageEnum.mm]: {
        path: 'market-making',
    },
    [PageEnum.contacts]: {
        path: 'contacts',
    },
    
}

export const HEADER_HEIGHT = 110
export const HEADER_MOBILE_HEIGHT = 104