import { backgrounds } from "./backgrounds/backgrounds";
import { icons } from "./icons/icons";
import { logo } from "./logo/logo";
import { prMarketing } from "./prMarketing/prMarketing";

export const imagesProvider = {
  icons,
  backgrounds,
  logo,
  prMarketing,
};
