import "./Header.scss";
import "./Header.adaptive.scss";
import HeaderLogo from "./logo/HeaderLogo";
import HeaderMenu from "./menu/HeaderMenu";
import HeaderLanguageSelector from "./languageSelector/HeaderLanguageSelector";

const Header = () => (
  <div className="il--header il--luminosity-card il--blur-card">
    <HeaderLogo />
    <div className="il--header--right-block">
      <HeaderMenu />
      <HeaderLanguageSelector />
    </div>
    <div className="il--header--effects-wrapper">
      <div className="il--header--effects"></div>
    </div>
  </div>
);

export default Header;
