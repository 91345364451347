import "./ServicePageCard.scss";
import "./ServicePageCard.adaptive.scss";
import { imagesProvider } from "../../../../../imagesProvider/imagesProvider";
import Icon from "../../../../../components/icon/Icon";

interface IProps {
  identificator: string;
  title: string;
  text: string;
  onClick: (identificator: string) => void;
}

const ServicePageCard = ({ identificator, title, text, onClick }: IProps) => {
  return (
    <div
      className="il--service-page-card il--blur-card"
      onClick={() => onClick(identificator)}
    >
      <div className="il--service-page-card--title-wrapper">
        <div className="il--service-page-card--title">{title}</div>
        {identificator && (
          <Icon width={18} iconPackage={imagesProvider.icons.arrowRightUp} />
        )}
      </div>
      <div className="il--service-page-card--text">{text}</div>
    </div>
  );
};

export default ServicePageCard;
