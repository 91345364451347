const BrandCodeContent = (t: any) => {
  return (
    <div>
      <p>{t("services.section1.content1.text1")}</p>
      <b>{t("services.section1.content1.text2")}</b>
      <p>
        <b>{t("services.section1.content1.text3")}</b>
      </p>
      <ul>
        <li>{t("services.section1.content1.text4")}</li>
        <li>{t("services.section1.content1.text5")}</li>
        <li>{t("services.section1.content1.text6")}</li>
        <li>{t("services.section1.content1.text7")}</li>
        <li>{t("services.section1.content1.text8")}</li>
        <li>{t("services.section1.content1.text9")}</li>
      </ul>
      <p>{t("services.section1.content1.text10")}</p>

      <span>{t("services.section1.content1.text11")}</span>
      <ul>
        <li>{t("services.section1.content1.text12")}</li>
        <li>{t("services.section1.content1.text13")}</li>
        <li>{t("services.section1.content1.text14")}</li>
        <li>{t("services.section1.content1.text15")}</li>
      </ul>
      <p>{t("services.section1.content1.text16")}</p>
    </div>
  );
};

export default BrandCodeContent;
