const WhitePaper = (t: any) => {
  return (
    <div>
      <p>{t("services.section1.content3.text1")}</p>
      <p>{t("services.section1.content3.text2")}</p>
      <p>{t("services.section1.content3.text3")}</p>
      <p>{t("services.section1.content3.text4")}</p>
      <p>{t("services.section1.content3.text5")}</p>

      <span>{t("services.section1.content3.text6")}</span>
      <ul>
        <li>{t("services.section1.content3.text7")}</li>
        <li>{t("services.section1.content3.text8")}</li>
        <li>{t("services.section1.content3.text9")}</li>
        <li>{t("services.section1.content3.text10")}</li>
        <li>{t("services.section1.content3.text11")}</li>
        <li>{t("services.section1.content3.text12")}</li>
        <li>{t("services.section1.content3.text13")}</li>
        <li>{t("services.section1.content3.text14")}</li>
        <li>{t("services.section1.content3.text15")}</li>
        <li>{t("services.section1.content3.text16")}</li>
      </ul>

      <p>{t("services.section1.content3.text17")}</p>
      <p>{t("services.section1.content3.text18")}</p>

      <p>{t("services.section1.content3.text19")}</p>
      <p>{t("services.section1.content3.text20")}</p>

      <p>{t("services.section1.content3.text21")}</p>
      <p>{t("services.section1.content3.text22")}</p>

      <p>{t("services.section1.content3.text23")}</p>
      <p>{t("services.section1.content3.text24")}</p>

      <p>{t("services.section1.content3.text25")}</p>
      <p>{t("services.section1.content3.text26")}</p>

      <p>{t("services.section1.content3.text27")}</p>
      <p>{t("services.section1.content3.text28")}</p>

      <p>{t("services.section1.content3.text29")}</p>
      <p>{t("services.section1.content3.text30")}</p>

      <span>{t("services.section1.content3.text31")}</span>
      <ul>
        <li>{t("services.section1.content3.text32")}</li>
        <li>{t("services.section1.content3.text33")}</li>
        <li>{t("services.section1.content3.text34")}</li>
        <li>{t("services.section1.content3.text35")}</li>
      </ul>

      <p>{t("services.section1.content3.text36")}</p>
      <p>{t("services.section1.content3.text37")}</p>

      <p>{t("services.section1.content3.text38")}</p>
      <p>{t("services.section1.content3.text39")}</p>

      <span>{t("services.section1.content3.text40")}</span>
      <ul>
        <li>{t("services.section1.content3.text41")}</li>
        <li>{t("services.section1.content3.text42")}</li>
        <li>{t("services.section1.content3.text43")}</li>
        <li>{t("services.section1.content3.text44")}</li>
        <li>{t("services.section1.content3.text45")}</li>
        <li>{t("services.section1.content3.text46")}</li>
      </ul>

    </div>
  );
};

export default WhitePaper;
