import "./Services.scss";
import "./Services.adaptive.scss";
import ServicePage from "./page/ServicePage";
import { PageEnum } from "../../../common/types";
import { getServicesList } from "../../../content/services";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Services = () => {
  const { t } = useTranslation();

  const [
    currentServiceCategoryIdentificator,
    setCurrentServiceCategoryIdentificator,
  ] = useState(
    getServicesList(t).filter((item) => item.isVisible)[0].categoryIdentificator
  );

  return (
    <div className="il--services" id={PageEnum.services}>
      <div className="il--services--title">{t("services.title")}</div>
      <ServicePage
        servicesList={getServicesList(t)}
        currentServiceCategoryIdentificator={
          currentServiceCategoryIdentificator
        }
        onChangePage={(serviceCategoryIdentificator) =>
          setCurrentServiceCategoryIdentificator(serviceCategoryIdentificator)
        }
      />
    </div>
  );
};

export default Services;
