import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../content/data/en.json";
import ua from "../content/data/ua.json";
import ru from "../content/data/ru.json";
import de from "../content/data/de.json";

const resources = {
  en: {
    translation: {
      ...en,
    },
  },
  ua: {
    translation: {
      ...ua,
    },
  },
  ru: {
    translation: {
      ...ru,
    },
  },
  de: {
    translation: {
      ...de,
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
