import "./HeaderMenu.scss";
import "./HeaderMenu.adaptive.scss";
import { Link } from "react-scroll";
import { PageEnum } from "../../../../common/types";
import { useTranslation } from "react-i18next";

interface IProps {
  setIsExpanded?: (isExpanded: boolean) => void;
}

const HeaderMenuItems = ({ setIsExpanded = () => {} }: IProps) => {
  const { t } = useTranslation();

  const activeClass = "il--header-menu--item-active";

  return (
    <>
      <Link
        activeClass={activeClass}
        smooth
        spy
        to={PageEnum.home}
        offset={-100}
        onClick={() => {
          setIsExpanded(false);
        }}
      >
        {t("common.menu.home")}
      </Link>
      <Link
        activeClass={activeClass}
        smooth
        spy
        to={PageEnum.services}
        offset={-100}
        onClick={() => {
          setIsExpanded(false);
        }}
      >
        {t("common.menu.services")}
      </Link>
      <Link
        activeClass={activeClass}
        smooth
        spy
        to={PageEnum.products}
        offset={-100}
        onClick={() => {
          setIsExpanded(false);
        }}
      >
        {t("common.menu.products")}
      </Link>
      <Link
        activeClass={activeClass}
        smooth
        spy
        to={PageEnum.partners}
        offset={-100}
        onClick={() => {
          setIsExpanded(false);
        }}
      >
        {t("common.menu.partners")}
      </Link>
      <Link
        activeClass={activeClass}
        smooth
        spy
        to={PageEnum.pr}
        offset={-100}
        onClick={() => {
          setIsExpanded(false);
        }}
      >
        {t("common.menu.pr")}
      </Link>
      <Link
        activeClass={activeClass}
        smooth
        spy
        to={PageEnum.mm}
        offset={-100}
        onClick={() => {
          setIsExpanded(false);
        }}
      >
        {t("common.menu.mm")}
      </Link>

      <Link
        activeClass={activeClass}
        smooth
        spy
        to={PageEnum.contacts}
        offset={-100}
        onClick={() => {
          setIsExpanded(false);
        }}
      >
        {t("common.menu.contacts")}
      </Link>
    </>
  );
};

export default HeaderMenuItems;
