import { imagesProvider } from "../../../imagesProvider/imagesProvider";
import { Parallax } from "react-parallax";
import { ReactNode, useCallback } from "react";
import { PageEnum } from "../../../common/types";

interface IProps {
  children: ReactNode;
  sectionId?: string;
}

const ParallaxWrapper = ({ children, sectionId }: IProps) => {
  const getBgImage = useCallback(() => {
    switch (sectionId) {
      case PageEnum.home:
        return `url(${imagesProvider.backgrounds.main})`;
      case PageEnum.services:
        return `url(${imagesProvider.backgrounds.mainShadow})`;
      case PageEnum.mm:
        return `url(${imagesProvider.backgrounds.mm})`;
      case PageEnum.contacts:
        return `url(${imagesProvider.backgrounds.contacts})`;

      default:
        return `url(${imagesProvider.backgrounds.mainShadow})`;
    }
  }, []);

  return (
    <Parallax
      renderLayer={(percentage) => {
        let opacity = percentage;

        if (percentage > 1) {
          opacity = 2 - percentage;
        }

        return (
          <div
            style={{
              backgroundImage: getBgImage(),
              backgroundPosition: "center",
              position: "fixed",
              left: "0",
              top: "0",
              width: "100vw",
              height: "100vh",
              opacity: opacity,
              zIndex: 2,
            }}
          />
        );
      }}
    >
      {children}
    </Parallax>
  );
};

export default ParallaxWrapper;
