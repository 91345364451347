const PrPlan = (t: any) => {
  return (
    <div>
      <p>{t("services.section1.content6.text1")}</p>
      <p>{t("services.section1.content6.text2")}</p>

      <span>{t("services.section1.content6.text3")}</span>
      <ul>
        <li>{t("services.section1.content6.text4")}</li>
        <li>{t("services.section1.content6.text5")}</li>
        <li>{t("services.section1.content6.text6")}</li>
        <li>{t("services.section1.content6.text7")}</li>
      </ul>

    </div>
  );
};

export default PrPlan;
