export enum PageEnum {
  main = "main",
  home = "home",
  landing = "landing",
  services = "services",
  products = "products",
  productsV2 = "productsV2",
  partners = "partners",
  events = "events",
  pr = "pr",
  mm = "mm",
  contacts = "contacts",
}

export interface Page {
  path: string;
}

export type Language = "en" | "de" | "ru" | "ua";

export interface SectionRefs {
  landingRef: React.RefObject<HTMLDivElement>;
  homeRef: React.RefObject<HTMLDivElement>;
  servicesRef: React.RefObject<HTMLDivElement>;
  productsRef: React.RefObject<HTMLDivElement>;
  partnersRef: React.RefObject<HTMLDivElement>;
  eventsRef: React.RefObject<HTMLDivElement>;
  prRef: React.RefObject<HTMLDivElement>;
  mmRef: React.RefObject<HTMLDivElement>;
  contactsRef: React.RefObject<HTMLDivElement>;
}
