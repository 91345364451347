// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/react-scripts/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../node_modules/react-scripts/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!./assets/fonts/Syne/Syne.scss";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/react-scripts/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../node_modules/react-scripts/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!./assets/fonts/Poppins/Poppins.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html,\nbody {\n  font-family: \"Syne\";\n  color: #ffffff;\n  background-color: #021127;\n}\n\n.il--blur-card {\n  background: rgba(99, 99, 99, 0.1);\n  border-radius: 16px;\n  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);\n  backdrop-filter: blur(9.5px);\n  -webkit-backdrop-filter: blur(9.5px);\n  transition: 0.1s;\n}\n.il--blur-card.il--blur-card-hovered:hover {\n  background: rgba(0, 255, 179, 0.1);\n  box-shadow: 0 4px 30px rgba(0, 255, 179, 0.1);\n}", "",{"version":3,"sources":["webpack://./src/App.scss","webpack://./src/_vars.scss"],"names":[],"mappings":"AAKA;;EAEE,mBAAA;EACA,cCLY;EDMZ,yBCRc;ADMhB;;AASA;EACE,iCAAA;EACA,mBAAA;EACA,yCAAA;EACA,4BAAA;EACA,oCAAA;EACA,gBAAA;AANF;AAQE;EACE,kCAAA;EACA,6CAAA;AANJ","sourcesContent":["@use '../src/vars' as v;\n\n@import url(\"../src/assets/fonts/Syne/Syne.scss\");\n@import url(\"../src/assets/fonts/Poppins/Poppins.scss\");\n\nhtml,\nbody {\n  font-family: \"Syne\";\n  color: v.$white-color;\n  background-color: v.$bg-main-color;\n}\n\n.il--anchor {\n  // z-index: 1;\n}\n\n.il--blur-card {\n  background: rgba(99, 99, 99, 0.1);\n  border-radius: 16px;\n  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);\n  backdrop-filter: blur(9.5px);\n  -webkit-backdrop-filter: blur(9.5px);\n  transition: 0.1s;\n\n  &.il--blur-card-hovered:hover {\n    background: rgba(0, 255, 179, 0.1);\n    box-shadow: 0 4px 30px rgba(0, 255, 179, 0.1);\n  }\n}\n","$bg-color: #010609;\r\n$bg-main-color: #021127;\r\n$main-color: #00ffb3;\r\n$white-color: #ffffff;\r\n$dark-gray-color: rgba(255, 255, 255, 0.18);\r\n$dark-gray-active-color: rgba(255, 255, 255, 0.1);\r\n\r\n$secondary-font-family: 'Poppins';\r\n\r\n$header-height: 100px;\r\n$header-mobile-height: 68px;\r\n\r\n$default-horizontal-padding: 7%;\r\n$default-mobile-horizontal-padding: 28px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
