import "./Home.scss";
import "./Home.adaptive.scss";
import { imagesProvider } from "../../../imagesProvider/imagesProvider";
import { PageEnum } from "../../../common/types";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();

  return (
    <div id={PageEnum.home} className="il--home">
      <div className="il--home--extra-bg-wrapper">
        <div>
          {/* <img src={imagesProvider.backgrounds.homePyramide} alt="bg" /> */}
        </div>
      </div>
      <div className="il--home--content-wrapper">
        <div className="il--home--text">{t("home.title")}</div>
        <div className="il--home--subtext">{t("home.subTitle")}</div>
        {/* <div className='il--home--navigation'>
                        <PrimaryButton
                            iconPackage={imagesProvider.icons.arrowRightDown}
                            title={t("home.buttonTitle")}
                            // onClick={() => navigate('/landing')}
                        />
                    </div> */}
      </div>
    </div>
  );
};

export default Home;
