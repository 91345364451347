import "./ServicePageMenu.scss";
import ServicePageMenuItem from "./item/ServicePageMenuItem";
import { ServiceData } from "../../shared/servicesTypes";

interface IProps {
  servicesList: ServiceData[];
  currentServiceIdentificator: string;
  onChangePage: (serviceIdentificator: string) => void;
}

const ServicePageMenu = ({
  servicesList,
  currentServiceIdentificator,
  onChangePage,
}: IProps) => {
  return (
    <div className="il--service-page-menu">
      {servicesList
        .filter((item) => item.isVisible)
        .map((m, i) => (
          <ServicePageMenuItem
            key={i}
            isActive={currentServiceIdentificator === m.categoryIdentificator}
            serviceIdentificator={m.categoryIdentificator}
            onClick={onChangePage}
            iconPackage={m.iconPackage}
            title={m.categoryName}
          />
        ))}
    </div>
  );
};

export default ServicePageMenu;
