import { Route, Routes } from "react-router-dom";
import Main from "../containers/pages/main/Main";
import { PAGES_DATA } from "../common/consts";
import Landing from "../containers/pages/landing/Landing";

const PagesRoute = () => (
  <Routes>
    <Route path={PAGES_DATA.main.path} element={<Main />} />
    {/* <Route path={PAGES_DATA.landing.path} element={<Main />} /> */}
  </Routes>
);

export default PagesRoute;
