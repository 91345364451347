import "./LanguageButton.scss";
import Icon from "../icon/Icon";
import { IconPackage, IconStateEnum } from "../../imagesProvider/icons/icons";
import { useTranslation } from "react-i18next";

interface IProps {
  languageCode?: string;
  iconPackage: IconPackage;
  onClick: () => void;
}

const LanguageButton = ({ iconPackage, onClick }: IProps) => {
  const { i18n } = useTranslation();

  return (
    <div className="il--language-button" onClick={onClick}>
      <div className="il--language-button--inner">
        <div className="il--language-button--language-title">
          {i18n.language.toUpperCase()}
        </div>
        <div>
          <div className="il--language-button--icon-main">
            <Icon
              width={5}
              height={5}
              iconPackage={iconPackage}
              iconState={IconStateEnum.Main}
            />
          </div>
          <div className="il--language-button--icon-hover">
            <Icon
              width={5}
              height={5}
              iconPackage={iconPackage}
              iconState={IconStateEnum.Hover}
            />
          </div>
          <div className="il--language-button--icon-press">
            <Icon
              width={5}
              height={5}
              iconPackage={iconPackage}
              iconState={IconStateEnum.Hover}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageButton;
