import "./Modal.scss";
import "./Modal.adaptive.scss";
import IconButton from "../iconButton/IconButton";
import { imagesProvider } from "../../imagesProvider/imagesProvider";
import Scrollbars from "react-custom-scrollbars-2";
import { useRef } from "react";
import usePortal from "react-useportal";

interface IProps {
  title?: string;
  subtitle?: string | JSX.Element;
  children?: React.ReactNode;
  onClose?: () => void;
}

const Modal = ({ title, subtitle, children, onClose }: IProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { Portal } = usePortal();

  return (
    <Portal>
      <div className="il--modal--overlay" onClick={onClose}></div>
      <div className="il--modal il--blur-card">
        <div className="il--modal--close-button-wrapper">
          <IconButton
            iconPackage={imagesProvider.icons.cross}
            onClick={onClose}
            useOnTouch={true}
          />
        </div>
        <div
          ref={wrapperRef}
          className="il--modal--header"
          style={{
            height: "auto",
          }}
        >
          {title ? <div className="il--modal--title">{title}</div> : null}
          {subtitle ? (
            <div className="il--modal--subtitle">{subtitle}</div>
          ) : null}
        </div>
        <Scrollbars
          style={{
            height: `calc(100vh - 200px)`,
          }}
          renderView={(props) => (
            <div {...props} style={{ ...props.style, overflowX: "hidden" }} />
          )}
        >
          {children}
        </Scrollbars>
      </div>
    </Portal>
  );
};

export default Modal;
