import "./Main.scss";
import Home from "../home/Home";
import Contacts from "../contacts/Contacts";
import MarketMaking from "../marketMaking/MarketMaking";
import PR from "../pr/PR";
import Partners from "../partners/Partners";
import Products from "../products/Products";
import Services from "../services/Services";
import { PageEnum } from "../../../common/types";
import Layout from "../../layout/Layout";
import ParallaxWrapper from "../../layout/ParallaxWrapper/ParallaxWrapper";

const Main = () => {
  return (
    <Layout>
      <div className="il--main">
        <ParallaxWrapper sectionId={PageEnum.home}>
          <Home />
        </ParallaxWrapper>
        <ParallaxWrapper sectionId={PageEnum.pr}>
          <Services />
        </ParallaxWrapper>
        <ParallaxWrapper sectionId={PageEnum.products}>
          <Products />
        </ParallaxWrapper>
        <ParallaxWrapper sectionId={PageEnum.partners}>
          <Partners />
        </ParallaxWrapper>
        <ParallaxWrapper sectionId={PageEnum.pr}>
          <PR />
        </ParallaxWrapper>
        <ParallaxWrapper sectionId={PageEnum.mm}>
          <MarketMaking />
        </ParallaxWrapper>
        <ParallaxWrapper sectionId={PageEnum.contacts}>
          <Contacts />
        </ParallaxWrapper>
      </div>
    </Layout>
  );
};

export default Main;
