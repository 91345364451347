import "./ProductBriefItem.scss";
import "./ProductBriefItem.adaptive.scss";
import { imagesProvider } from "../../../../imagesProvider/imagesProvider";
import { useTranslation } from "react-i18next";

interface IProps {
  title: string;
  briefPoints: string[];
  handleClick: () => void;
}

const ProductBriefItem = ({ title, briefPoints, handleClick }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className="il--product-brief-card">
      <div className="il--product-brief-item" onClick={handleClick}>
        <div className="il--product-brief-item--inner">
          <div className="il--product-brief-item--title">{title}</div>
          <div className="il--product-brief-item--content">
            <ul>
              {briefPoints.map((m, i) => (
                <li key={i}>{m}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="il--product-brief-item--footer">
          <span>{t("layout.detail")}</span>
          <img
            width={12}
            src={imagesProvider.icons.arrowRightUp.active}
            alt="icon"
          />
        </div>
      </div>
    </div>
  );
};

export default ProductBriefItem;
