import "./Partners.scss";
import "./Partners.adaptive.scss";
import PartnerSlider from "./slider/PartnerSlider";
import { PageEnum } from "../../../common/types";
import { partnersList } from "./partnersList";
import { useTranslation } from "react-i18next";

const Partners = () => {
  const { t } = useTranslation();

  return (
    <div id={PageEnum.partners} className="il--partners">
      <div className="il--partners--inner">
        <div className="il--partners--left-wrapper">
          <div className="il--partners--title">{t("partners.title")}</div>
          <div className="il--partners--text">{t("partners.subTitle")}</div>
          {/* <div className='il--partners--navigator'>
                            <Link
                                to='/landing'
                            >
                                <PrimaryButton
                                    iconPackage={imagesProvider.icons.arrowRightDown}
                                    title='Explore'
                                />
                            </Link>
                        </div> */}
        </div>
        <div className="il--partners--right-wrapper">
          <PartnerSlider sliderItems={partnersList} />
        </div>
      </div>
    </div>
  );
};

export default Partners;
