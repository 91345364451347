import "./SliderButton.scss";
import "./SliderButton.adaptive.scss";
import { IconPackage } from "../../imagesProvider/icons/icons";
import Icon from "../icon/Icon";

interface IProps {
  title?: string;
  iconPackage?: IconPackage;
  position: "left" | "right";
  onClick?: () => void;
}

const SliderButton = ({ title, iconPackage, position, onClick }: IProps) => {
  return (
    <div className="il--slider-button" onClick={onClick}>
      {position === "left" ? (
        <div className="il--slider-button--border-sector-left">
          <svg
            width="24"
            height="110"
            viewBox="0 0 24 110"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.5529 109.447C16.4028 102.297 10.731 93.8087 6.86137 84.4666C2.99175 75.1246 1.00009 65.1118 1.00009 55C1.00009 44.8882 2.99175 34.8754 6.86137 25.5334C10.731 16.1913 16.4028 7.70289 23.5529 0.552776"
              stroke="white"
            />
          </svg>
        </div>
      ) : (
        <div className="il--slider-button--border-sector-right">
          <svg
            width="24"
            height="110"
            viewBox="0 0 24 110"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.447132 109.447C7.59724 102.297 13.269 93.8087 17.1386 84.4666C21.0082 75.1246 22.9999 65.1118 22.9999 55C22.9999 44.8882 21.0082 34.8754 17.1386 25.5334C13.269 16.1913 7.59724 7.70289 0.44713 0.552776"
              stroke="white"
            />
          </svg>
        </div>
      )}
      {position === "left" ? (
        <div className="il--slider-button--adaptive-border-sector-left">
          <svg
            width="10"
            height="42"
            viewBox="0 0 10 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.20136 1.20102C6.60132 3.80106 4.53885 6.88775 3.13172 10.2849C1.72459 13.682 1.00035 17.323 1.00035 21C1.00035 24.677 1.72459 28.318 3.13172 31.7151C4.53885 35.1123 6.60132 38.199 9.20136 40.799"
              stroke="white"
            />
          </svg>
        </div>
      ) : (
        <div className="il--slider-button--adaptive-border-sector-right">
          <svg
            width="10"
            height="42"
            viewBox="0 0 10 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.799313 40.799C3.39935 38.1989 5.46182 35.1123 6.86895 31.7151C8.27608 28.318 9.00032 24.677 9.00033 21C9.00033 17.323 8.27608 13.682 6.86895 10.2849C5.46182 6.88774 3.39936 3.80105 0.799316 1.20101"
              stroke="white"
            />
          </svg>
        </div>
      )}
      <div className="il--slider-button--inner">
        {position === "left" && iconPackage ? (
          <div className="il--slider-button--icon">
            <Icon width={19} height={19} iconPackage={iconPackage} />
          </div>
        ) : null}
        {title ? <div className="il--slider-button--title">{title}</div> : null}
        {position === "right" && iconPackage ? (
          <div className="il--slider-button--icon">
            <Icon width={19} height={19} iconPackage={iconPackage} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SliderButton;
