import "./Layout.scss";
import "./Layout.adaptive.scss";

import * as React from "react";
import Header from "./header/Header";
import { BackgroundSrc } from "../../imagesProvider/backgrounds/backgrounds";
import { imagesProvider } from "../../imagesProvider/imagesProvider";
import { SectionRefs } from "../../common/types";

interface IProps {
  children: React.ReactNode;
}

interface IState {
  bgMainOpacity: number;
  bgSecondaryOpacity: number;
  bgMMOpacity: number;
  bgContactsOpacity: number;
}

const Layout = ({ children }: IProps) => {
  return (
    <>
      <>
        {/* <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100vh",
            backgroundImage: `url(${imagesProvider.backgrounds.main})`,
            backgroundAttachment: "fixed",
            opacity: this.state.bgMainOpacity,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100vh",
            backgroundImage: `url(${imagesProvider.backgrounds.mainShadow})`,
            backgroundAttachment: "fixed",
            opacity: this.state.bgSecondaryOpacity,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100vh",
            backgroundImage: `url(${imagesProvider.backgrounds.mm})`,
            backgroundAttachment: "fixed",
            opacity: this.state.bgMMOpacity,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100vh",
            backgroundImage: `url(${imagesProvider.backgrounds.contacts})`,
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
            backgroundPosition: "center",
            opacity: this.state.bgContactsOpacity,
          }}
        ></div> */}
      </>

      <div id="layout" className="il--layout">
        <Header />
        <div className="il--layout--content">{children}</div>
      </div>
    </>
  );
};

// private readonly scrollHandler = (): void => {
//   if (!this.props.sectionRefs) {
//     return;
//   }

//   const bgMainTopPosition = this.props.sectionRefs.homeRef.current?.offsetTop;
//   const bgSecondaryTopPosition =
//     this.props.sectionRefs.servicesRef.current?.offsetTop;
//   const bgPRTopPosition = this.props.sectionRefs.prRef.current?.offsetTop;
//   const bgMMTopPosition = this.props.sectionRefs.mmRef.current?.offsetTop;
//   const bgPartnersTopPosition =
//     this.props.sectionRefs.partnersRef.current?.offsetTop;
//   const bgContactsTopPosition =
//     this.props.sectionRefs.contactsRef.current?.offsetTop;

//   if (
//     !bgMainTopPosition ||
//     !bgSecondaryTopPosition ||
//     !bgPRTopPosition ||
//     !bgMMTopPosition ||
//     !bgContactsTopPosition ||
//     !bgPartnersTopPosition
//   ) {
//     return;
//   }

//   let bgMainOpacity =
//     (1 -
//       (window.scrollY - bgMainTopPosition) /
//         (bgSecondaryTopPosition - bgMainTopPosition)) *
//     1.3;
//   let bgSecondaryOpacity =
//     ((window.scrollY - bgMainTopPosition) /
//       (bgSecondaryTopPosition - bgMainTopPosition)) *
//     1.3;

//   let bgMMOpacity =
//     ((window.scrollY - bgPRTopPosition) /
//       (bgMMTopPosition - bgPRTopPosition)) *
//     1.3;
//   console.log(bgMMOpacity, 111111);

//   let bgContactsOpacity = 0;

//   if (bgMainOpacity > 1) {
//     bgMainOpacity = 1;
//   }

//   if (bgMainOpacity < 0) {
//     bgMainOpacity = 0;
//   }

//   if (bgSecondaryOpacity > 1) {
//     bgSecondaryOpacity = 1;
//   }

//   if (bgSecondaryOpacity < 0) {
//     bgSecondaryOpacity = 0;
//   }

//   if (bgMMOpacity > 1) {
//     bgMMOpacity = 1 - (bgMMOpacity - 1);

//     bgContactsOpacity =
//       ((window.scrollY - bgPRTopPosition) /
//         (bgMMTopPosition - bgPRTopPosition)) *
//         1.1 -
//       1;
//   }

//   this.setState({
//     bgMainOpacity: bgMainOpacity,
//     bgSecondaryOpacity: bgSecondaryOpacity,
//     bgMMOpacity: bgMMOpacity,
//     bgContactsOpacity: bgContactsOpacity,
//   });
// };

export default Layout;
