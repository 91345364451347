import "./PrimaryButton.scss";
import "./PrimaryButton.adaptive.scss";
import Icon from "../icon/Icon";
import { IconPackage, IconStateEnum } from "../../imagesProvider/icons/icons";

interface IProps {
  title: string;
  iconPackage: IconPackage;
  extendedWidth?: boolean;
  useOnTouch?: boolean;
  onClick?: () => void;
}

const PrimaryButton = ({
  title,
  iconPackage,
  extendedWidth,
  useOnTouch,
  onClick,
}: IProps) => {
  return (
    <div
      className="il--primary-button il--primary-button--extended-width"
      onClick={onClick}
    >
      <div className="il--primary-button--inner">
        <div className="il--primary-button--title">{title}</div>
        {iconPackage ? (
          <div className="il--primary-button--icon-wrapper">
            <div className="il--primary-button--icon-main">
              <Icon
                width={12}
                height={12}
                iconPackage={iconPackage}
                iconState={IconStateEnum.Main}
              />
            </div>
            <div className="il--primary-button--icon-hover">
              <Icon
                width={12}
                height={12}
                iconPackage={iconPackage}
                iconState={IconStateEnum.Hover}
              />
            </div>
            <div className="il--primary-button--icon-press">
              <Icon
                width={12}
                height={12}
                iconPackage={iconPackage}
                iconState={IconStateEnum.Press}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PrimaryButton;
