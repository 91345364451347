import "./ProductModalContent.scss";
import "./ProductModalContent.adaptive.scss";

interface IProps {
  title: string;
  text: string;
  list: string;
  text2: string;
  amount: string;
}

const ProductModalContent = ({ title, text, list, text2, amount }: IProps) => {
  return (
    <div className="il--product-modal-content">
      <div className="il--product-modal-content--title">{title}</div>
      <div className="il--product-modal-content--text">{text}</div>
      <div className="il--product-modal-content--text il--product-modal-content--list">{list}</div>
      <div className="il--product-modal-content--text">{text2}</div>
      <div className="il--product-modal-content--amount">{amount}</div>
      {/* <div className='il--product-modal-content--nav'>
                    <PrimaryButton
                        title='Explore'
                        iconPackage={imagesProvider.icons.arrowRightDown}
                    />
                </div> */}
    </div>
  );
};

export default ProductModalContent;
