const P2Egame = (t: any) => {
  return (
    <div>
      <p>{t("services.section2.content5.text1")}</p>
      <p>{t("services.section2.content5.text2")}</p>
    </div>
  );
};

export default P2Egame;
