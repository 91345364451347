const Tokenomics = (t: any) => {
  return (
    <div>
      <p>{t("services.section1.content2.text1")}</p>
      <p>{t("services.section1.content2.text2")}</p>
      <p>{t("services.section1.content2.text3")}</p>
      <ul>
        <li>{t("services.section1.content2.text4")}</li>
        <li>{t("services.section1.content2.text5")}</li>
        <li>{t("services.section1.content2.text6")}</li>
        <li>{t("services.section1.content2.text7")}</li>
        <li>{t("services.section1.content2.text8")}</li>
        <li>{t("services.section1.content2.text9")}</li>
        <li>{t("services.section1.content2.text10")}</li>
      </ul>
      <p>{t("services.section1.content2.text11")}</p>
      <p>{t("services.section1.content2.text12")}</p>
    </div>
  );
};

export default Tokenomics;
