import { PartnerItem } from "../shared/partnersTypes";
import Slider from "../../../../components/slider/Slider";

interface IProps {
  sliderItems: PartnerItem[];
}

const PartnerSlider = ({ sliderItems }: IProps) => {
  const settings = {
    className: "center",
    dots: false,
    infinite: true,
    speed: 500,
    rows: 2,
    slidesPerRow: 2,
    responsive: [
      {
        breakpoint: 478,
        settings: {
          slidesPerRow: 1,
          rows: 2,
        },
      },
    ],
  };

  return (
    <Slider settings={settings}>
      {sliderItems.map((slide, i) => (
        <div className="il--slide-box" key={i}>
          <div className="img-wrapper il--blur-card">
            <img src={slide.logo.main} alt={"logo"} />
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default PartnerSlider;
