import BgMainPng from "../../assets/images/png/bg-main.png";
import BgLandingPng from "../../assets/images/png/bg-landing.svg";
import BgMainShadowPng from "../../assets/images/png/bg-main-shadow.png";
import BgMarketMakingJpg from "../../assets/images/png/bg-market-making.png";
import BgContactsJpg from "../../assets/images/png/bg-contacts.jpg";
import BgEventsDefaultPng from "../../assets/images/png/bg-events-default.png";
import BgHomePyramidePng from "../../assets/images/png/bg-home-pyramide.png";
import BgHomePyramideSvg from "../../assets/images/svg/home/bg-home-pyramide.svg";

import ServiceProductDocsBgSvg from "../../assets/images/svg/services/service-product-docs_bg.svg";

import ServiceAdvisingBgSvg from "../../assets/images/svg/services/service-advising_bg.svg";

import ServiceBlockchainBgSvg from "../../assets/images/svg/services/service-blockchain_bg.svg";

import ServiceBlockchainDocsBgSvg from "../../assets/images/svg/services/service-blockchaindocs_bg.svg";

import ServiceLegalBgSvg from "../../assets/images/svg/services/service-legal_bg.svg";

import ServiceListingsBgSvg from "../../assets/images/svg/services/service-listings_bg.svg";

import ServiceMarketStrategyBgSvg from "../../assets/images/svg/services/service-market-strategy_bg.svg";

import ServiceMMBgSvg from "../../assets/images/svg/services/service-mm_bg.svg";

import ServicePRBgSvg from "../../assets/images/svg/services/service-pr_bg.svg";
import ServicePR2BgSvg from "../../assets/images/svg/services/service-pr-2_bg.svg";

import ServiceSmmBgSvg from "../../assets/images/svg/services/service-smm_bg.svg";

import ServiceTokensaleBgSvg from "../../assets/images/svg/services/service-tokensale_bg.svg";

type Opaque<T, K> = T & { __opaque__: K };
export type BackgroundSrc = Opaque<string, "BackgroundSrc">;

interface Backgrounds {
  main: BackgroundSrc;
  mainShadow: BackgroundSrc;
  landing: BackgroundSrc;
  mm: BackgroundSrc;
  contacts: BackgroundSrc;
  eventsDefault: BackgroundSrc;
  homePyramide: BackgroundSrc;
  homePyramideSvg: BackgroundSrc;
  serviceProductDocs: BackgroundSrc;
  serviceAdvising: BackgroundSrc;
  serviceBlockchain: BackgroundSrc;
  serviceBlockchainDocs: BackgroundSrc;
  serviceLegal: BackgroundSrc;
  serviceListings: BackgroundSrc;
  serviceMarketStrategy: BackgroundSrc;
  serviceMM: BackgroundSrc;
  servicePR: BackgroundSrc;
  servicePR2: BackgroundSrc;
  serviceSmm: BackgroundSrc;
  serviceTokensale: BackgroundSrc;
}

export const backgrounds: Backgrounds = {
  main: BgMainPng as BackgroundSrc,
  mainShadow: BgMainShadowPng as BackgroundSrc,
  landing: BgLandingPng as BackgroundSrc,
  contacts: BgContactsJpg as BackgroundSrc,
  mm: BgMarketMakingJpg as BackgroundSrc,
  eventsDefault: BgEventsDefaultPng as BackgroundSrc,
  homePyramide: BgHomePyramidePng as BackgroundSrc,
  homePyramideSvg: BgHomePyramideSvg as BackgroundSrc,
  serviceProductDocs: ServiceProductDocsBgSvg as BackgroundSrc,
  serviceAdvising: ServiceAdvisingBgSvg as BackgroundSrc,
  serviceBlockchain: ServiceBlockchainBgSvg as BackgroundSrc,
  serviceBlockchainDocs: ServiceBlockchainDocsBgSvg as BackgroundSrc,
  serviceLegal: ServiceLegalBgSvg as BackgroundSrc,
  serviceListings: ServiceListingsBgSvg as BackgroundSrc,
  serviceMarketStrategy: ServiceMarketStrategyBgSvg as BackgroundSrc,
  serviceMM: ServiceMMBgSvg as BackgroundSrc,
  servicePR: ServicePRBgSvg as BackgroundSrc,
  servicePR2: ServicePR2BgSvg as BackgroundSrc,
  serviceSmm: ServiceSmmBgSvg as BackgroundSrc,
  serviceTokensale: ServiceTokensaleBgSvg as BackgroundSrc,
};
