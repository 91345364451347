import "./MarketMaking.scss";
import "./MarketMaking.adaptive.scss";
import { PageEnum } from "../../../common/types";
import { useTranslation } from "react-i18next";

const MarketMaking = () => {
  const { t } = useTranslation();

  return (
    <div id={PageEnum.mm} className="il--market-making">
      <div className="il--market-making--inner">
        <div className="il--market-making--title">
          {t("marketMaking.title")}
        </div>
        <div className="il--market-making--text">
          {t("marketMaking.subTitle")}
        </div>
      </div>
    </div>
  );
};

export default MarketMaking;
