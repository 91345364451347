const ListingCEX = (t: any) => {
  return (
    <div>
      <p>{t("services.section11.content1.text1")}</p>
      <p>{t("services.section11.content1.text2")}</p>

      <span>{t("services.section11.content1.text3")}</span>
      <ul>
        <li>{t("services.section11.content1.text4")}</li>
        <li>{t("services.section11.content1.text5")}</li>
        <li>{t("services.section11.content1.text6")}</li>
      </ul>

      <span>{t("services.section11.content1.text7")}</span>
      <ul>
        <li>{t("services.section11.content1.text8")}</li>
        <li>{t("services.section11.content1.text9")}</li>
        <li>{t("services.section11.content1.text10")}</li>
      </ul>

      <span>{t("services.section11.content1.text11")}</span>
      <ul>
        <li>{t("services.section11.content1.text12")}</li>
        <li>{t("services.section11.content1.text13")}</li>
        <li>{t("services.section11.content1.text14")}</li>
      </ul>

      <p>{t("services.section11.content1.text15")}</p>
      <p>{t("services.section11.content1.text16")}</p>
      <p>{t("services.section11.content1.text17")}</p>
      <p>{t("services.section11.content1.text18")}</p>

      <p><b>{t("services.section11.content1.text19")}</b></p>

      <span>{t("services.section11.content1.text20")}</span>
      <ul>
        <li>{t("services.section11.content1.text21")}</li>
        <li>{t("services.section11.content1.text22")}</li>
        <li>{t("services.section11.content1.text23")}</li>
        <li>{t("services.section11.content1.text24")}</li>
        <li>{t("services.section11.content1.text25")}</li>
      </ul>

      <p>{t("services.section11.content1.text26")}</p>

    </div>
  );
};

export default ListingCEX;
