// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Syne-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Syne-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Syne-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./Syne-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./Syne-ExtraBold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Syne\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n  font-weight: 100 200 300 400;\n}\n@font-face {\n  font-family: \"Syne\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n  font-weight: 500;\n}\n@font-face {\n  font-family: \"Syne\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");\n  font-weight: 600;\n}\n@font-face {\n  font-family: \"Syne\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\");\n  font-weight: 700;\n}\n@font-face {\n  font-family: \"Syne\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\");\n  font-weight: 800 900;\n}", "",{"version":3,"sources":["webpack://./src/assets/fonts/Syne/Syne.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,+DAAA;EACA,4BAAA;AACJ;AAEA;EACI,mBAAA;EACA,+DAAA;EACA,gBAAA;AAAJ;AAGA;EACI,mBAAA;EACA,+DAAA;EACA,gBAAA;AADJ;AAIA;EACI,mBAAA;EACA,+DAAA;EACA,gBAAA;AAFJ;AAKA;EACI,mBAAA;EACA,+DAAA;EACA,oBAAA;AAHJ","sourcesContent":["@font-face {\r\n    font-family: \"Syne\";\r\n    src: url(\"Syne-Regular.ttf\") format('truetype');\r\n    font-weight: 100 200 300 400;\r\n}\r\n\r\n@font-face {\r\n    font-family: \"Syne\";\r\n    src: url(\"Syne-Medium.ttf\") format('truetype');\r\n    font-weight: 500;\r\n}\r\n\r\n@font-face {\r\n    font-family: \"Syne\";\r\n    src: url(\"Syne-SemiBold.ttf\") format('truetype');\r\n    font-weight: 600;\r\n}\r\n\r\n@font-face {\r\n    font-family: \"Syne\";\r\n    src: url(\"Syne-Bold.ttf\") format('truetype');\r\n    font-weight: 700;\r\n}\r\n\r\n@font-face {\r\n    font-family: \"Syne\";\r\n    src: url(\"Syne-ExtraBold.ttf\") format('truetype');\r\n    font-weight: 800 900;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
