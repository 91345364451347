import "./ContactsLink.scss";
import { imagesProvider } from "../../../../imagesProvider/imagesProvider";

interface IProps {
  title: string;
  subTitle: string;
  link: string;
  logo: string;
}

const ContactsLink = ({ title, subTitle, link, logo }: IProps) => {
  return (
    <a target="_blank" href={link} rel="noreferrer">
      <div className="il--contacts-link il--blur-card il--blur-card-hovered">
        <div className="il--contacts-link--left-wrapper">
          <img src={logo} alt="icon" />
          <div style={{ paddingLeft: 16 }}>{title}</div>
        </div>
        <div className="il--contacts-link--right-wrapper">
          <div style={{ paddingRight: 16 }}>{subTitle}</div>
          <img src={imagesProvider.icons.arrowRightUp.main} alt="icon" />
        </div>
      </div>
    </a>
  );
};

export default ContactsLink;
