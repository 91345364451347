import "./ServicePageMenuItem.scss";
import Icon from "../../../../../../components/icon/Icon";
import {
  IconPackage,
  IconStateEnum,
} from "../../../../../../imagesProvider/icons/icons";

interface IProps {
  isActive: boolean;
  title: string;
  serviceIdentificator: string;
  iconPackage: IconPackage;
  onClick: (serviceIdentificator: string) => void;
}

const ServicePageMenuItem = ({
  isActive,
  title,
  serviceIdentificator,
  iconPackage,
  onClick,
}: IProps) => {
  const className =
    "il--service-page-menu-item" +
    (isActive ? " il--service-page-menu-item--active" : "");

  return (
    <div className={className} onClick={() => onClick(serviceIdentificator)}>
      <div className="il--service-page-menu-item--icon-wrapper">
        <Icon
          width={48}
          iconPackage={iconPackage}
          iconState={isActive ? IconStateEnum.Active : IconStateEnum.Main}
        />
      </div>
      <div className="il--service-page-menu-item--lefttop-corner"></div>
      <div className="il--service-page-menu-item--righttop-corner"></div>
      <div className="il--service-page-menu-item--leftbottom-corner"></div>
      <div className="il--service-page-menu-item--rightbottom-corner"></div>

      <div className="il--service-page-menu-item--title">{title}</div>
    </div>
  );
};

export default ServicePageMenuItem;
