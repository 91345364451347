import { imagesProvider } from "../imagesProvider/imagesProvider";

export const getContacts = (t: any) => [
  {
    title: "Telegram",
    subTitle: "@telegram",
    link: "https://t.me/vmofi",
    logo: imagesProvider.icons.telegram.main,
  },
  {
    title: "WhatsApp",
    subTitle: "@whatsapp",
    link: "https://wa.me/380505055310",
    logo: imagesProvider.icons.whatsup.main,
  },
  {
    title: t("contact.phone"),
    subTitle: "+971 55 422 0381",
    link: "tel:+971554220381",
    logo: imagesProvider.icons.phone.main,
  },
];
