import { imagesProvider } from "../../../imagesProvider/imagesProvider";
import { PartnerItem } from "./shared/partnersTypes";

export const partnersList: PartnerItem[] = [
  {
    logo: imagesProvider.icons.partner1,
  },
  {
    logo: imagesProvider.icons.partner2,
  },
  {
    logo: imagesProvider.icons.partner3,
  },
  {
    logo: imagesProvider.icons.partner4,
  },
  {
    logo: imagesProvider.icons.partner5,
  },
  {
    logo: imagesProvider.icons.partner6,
  },
  {
    logo: imagesProvider.icons.partner7,
  },
  {
    logo: imagesProvider.icons.partner8,
  },
  {
    logo: imagesProvider.icons.partner9,
  },
  {
    logo: imagesProvider.icons.partner10,
  },
  {
    logo: imagesProvider.icons.partner11,
  },
  {
    logo: imagesProvider.icons.partner12,
  },
];
