const PitchDeck = (t: any) => {
  return (
    <div>
      <p>{t("services.section1.content5.text1")}</p>
      <p>{t("services.section1.content5.text2")}</p>
      <p>{t("services.section1.content5.text3")}</p>

      <span>{t("services.section1.content5.text4")}</span>
      <ul>
        <li>{t("services.section1.content5.text5")}</li>
        <li>{t("services.section1.content5.text6")}</li>
        <li>{t("services.section1.content5.text7")}</li>
        <li>{t("services.section1.content5.text8")}</li>
        <li>{t("services.section1.content5.text9")}</li>
        <li>{t("services.section1.content5.text10")}</li>
        <li>{t("services.section1.content5.text11")}</li>
      </ul>

      <span>{t("services.section1.content5.text12")}</span>
      <ul>
        <li>{t("services.section1.content5.text13")}</li>
        <li>{t("services.section1.content5.text14")}</li>
        <li>{t("services.section1.content5.text15")}</li>
        <li>{t("services.section1.content5.text16")}</li>
        <li>{t("services.section1.content5.text17")}</li>
        <li>{t("services.section1.content5.text18")}</li>
        <li>{t("services.section1.content5.text19")}</li>
        <li>{t("services.section1.content5.text20")}</li>
        <li>{t("services.section1.content5.text21")}</li>
        <li>{t("services.section1.content5.text22")}</li>
        <li>{t("services.section1.content5.text23")}</li>
      </ul>

      <p>{t("services.section1.content5.text24")}</p>
      <p>{t("services.section1.content5.text25")}</p>
      <p>{t("services.section1.content5.text26")}</p>

    </div>
  );
};

export default PitchDeck;
