export const getProductsList = (t: any) => [
  {
    name: t("products.section1.title"),
    identificator: "1",
    briefPoints: [
      t("products.section1.label1"),
      t("products.section1.label2"),
      t("products.section1.label3"),
      t("products.section1.label4"),
      t("products.section1.label5"),
    ],
    modalText: t("products.section1.modalText"),
    modalList: t("products.section1.modalList"),
    modalText2: t("products.section1.modalText2"),
  },
  {
    name: t("products.section2.title"),
    identificator: "2",
    briefPoints: [
      t("products.section2.label1"),
      t("products.section2.label2"),
      t("products.section2.label3"),
      t("products.section2.label4"),
      t("products.section2.label5"),
      t("products.section2.label6"),
      t("products.section2.label7"),
    ],
    modalText: t("products.section2.modalText"),
    modalList: t("products.section2.modalList"),
    modalText2: t("products.section2.modalText2"),
  },
  {
    name: t("products.section3.title"),
    identificator: "3",
    briefPoints: [
      t("products.section3.label1"),
      t("products.section3.label2"),
      t("products.section3.label3"),
    ],
    modalText: t("products.section3.modalText"),
    modalList: t("products.section3.modalList"),
    modalText2: t("products.section3.modalText2"),
  },
  {
    name: t("products.section4.title"),
    identificator: "4",
    briefPoints: [
      t("products.section4.label1"),
      t("products.section4.label2"),
      t("products.section4.label3"),
      t("products.section4.label4"),
    ],
    modalText: t("products.section4.modalText"),
    modalList: t("products.section4.modalList"),
    modalText2: t("products.section4.modalText2"),
  },
];
