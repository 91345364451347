const MarketingPlan = (t: any) => {
  return (
    <div>
      <p>{t("services.section1.content7.text1")}</p>

      <span>{t("services.section1.content7.text2")}</span>
      <ul>
        <li>{t("services.section1.content7.text3")}</li>
        <li>{t("services.section1.content7.text4")}</li>
        <li>{t("services.section1.content7.text5")}</li>
        <li>{t("services.section1.content7.text6")}</li>
        <li>{t("services.section1.content7.text7")}</li>
        <li>{t("services.section1.content7.text8")}</li>
        <li>{t("services.section1.content7.text9")}</li>
        <li>{t("services.section1.content7.text10")}</li>
      </ul>

      <span>{t("services.section1.content7.text11")}</span>
      <ul>
        <li>{t("services.section1.content7.text12")}</li>
        <li>{t("services.section1.content7.text13")}</li>
        <li>{t("services.section1.content7.text14")}</li>
        <li>{t("services.section1.content7.text15")}</li>
      </ul>

      <p>{t("services.section1.content7.text16")}</p>

      <span>{t("services.section1.content7.text17")}</span>
      <ul>
        <li>{t("services.section1.content7.text18")}</li>
        <li>{t("services.section1.content7.text19")}</li>
        <li>{t("services.section1.content7.text20")}</li>
        <li>{t("services.section1.content7.text21")}</li>
      </ul>

    </div>
  );
};

export default MarketingPlan;
