import "./Products.scss";
import "./Products.adaptive.scss";
import ProductBriefItem from "./briefItem/ProductBriefItem";
import { ProductData } from "./shared/sharedTypes";
import Modal from "../../../components/modal/Modal";
import ProductModalContent from "./modalContent/ProductModalContent";
import { PageEnum } from "../../../common/types";
import { useState } from "react";
import Slider from "../../../components/slider/Slider";
import { useTranslation } from "react-i18next";
import { getProductsList } from "../../../content/products";

const Products = () => {
  const { t } = useTranslation();

  const [detailedProductIdentificator, setDetailedProductIdentificator] =
    useState("");

  const getProductData = (identificator: string): ProductData | null =>
    getProductsList(t).find((f) => f.identificator === identificator) || null;

  const sliderSetting = {
    infinite: true,
    speed: 500,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 998,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 664,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {detailedProductIdentificator ? (
        <Modal
          title={getProductData(detailedProductIdentificator)?.name || ""}
          onClose={() => setDetailedProductIdentificator("")}
        >
          <ProductModalContent
            title=""
            text={getProductData(detailedProductIdentificator)?.modalText || ""}
            list={getProductData(detailedProductIdentificator)?.modalList || ""}
            text2={getProductData(detailedProductIdentificator)?.modalText2 || ""}
            amount=""
          />
        </Modal>
      ) : null}

      <div id={PageEnum.products} className="il--products">
        <div className="il--products--title">{t("products.title")}</div>
        <div className="il--products--content-wrapper">
          <div className="il--products--content">
            <Slider settings={sliderSetting}>
              {getProductsList(t).map((m, i) => (
                <div
                  key={i}
                  className="il--products--brief-item-wrapper il--blur-card il--blur-card-hovered"
                >
                  <ProductBriefItem
                    title={m.name}
                    briefPoints={m.briefPoints}
                    handleClick={() =>
                      setDetailedProductIdentificator(m.identificator)
                    }
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
