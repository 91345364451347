import "./Slider.scss";
import "./Slider.adaptive.scss";
import { default as SlickSlider, Settings } from "react-slick";
import { ReactNode, useRef } from "react";
import SliderButton from "../sliderButton/SliderButton";
import { useTranslation } from "react-i18next";

interface IProps {
  children: ReactNode;
  settings: Settings;
}

const Slider = ({ children, settings }: IProps) => {
  const { t } = useTranslation();
  const sliderRef = useRef<SlickSlider>(null);

  return (
    <div className="il--slider">
      <SlickSlider ref={sliderRef} {...settings}>
        {children}
      </SlickSlider>
      <div className="il--slider--prev-wrapper">
        <SliderButton
          position="left"
          title={t("layout.prevSliderButton") as string}
          onClick={() => sliderRef?.current?.slickPrev()}
        />
      </div>
      <div className="il--slider--next-wrapper">
        <SliderButton
          position="right"
          title={t("layout.nextSliderButton") as string}
          onClick={() => sliderRef?.current?.slickNext()}
        />
      </div>
    </div>
  );
};

export default Slider;
