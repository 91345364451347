const LandingPage = (t: any) => {
  return (
    <div>
      <p>{t("services.section4.content1.text1")}</p>
      <p>{t("services.section4.content1.text2")}</p>
    </div>
  );
};

export default LandingPage;
