import "./HeaderMenu.scss";
import "./HeaderMenu.adaptive.scss";
import { imagesProvider } from "../../../../imagesProvider/imagesProvider";
import IconButton from "../../../../components/iconButton/IconButton";
import HeaderLogo from "../logo/HeaderLogo";
import { useState } from "react";
import HeaderMenuItems from "./HeaderMenuItems";

const HeaderMenu = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <div className="il--header-menu">
        <HeaderMenuItems />
      </div>
      <div className="il--header-menu--mobile">
        {isExpanded ? (
          <div
            className="il--header-menu--mobile-modal"
            style={{
              backgroundImage: `url(${imagesProvider.backgrounds.main}`,
              backgroundPosition: "center",
            }}
          >
            <div className="il--blur-card">
              <div className="il--header-menu--mobile-top-wrapper">
                <div className="il--header-menu--mobile-logo-wrapper">
                  <HeaderLogo />
                </div>
                <div className="il--header-menu--mobile-close-button-wrapper">
                  <IconButton
                    iconPackage={imagesProvider.icons.cross}
                    onClick={() => setIsExpanded(false)}
                    useOnTouch={true}
                  />
                </div>
              </div>
              <div className="il--header-menu--mobile-content">
                <HeaderMenuItems setIsExpanded={setIsExpanded} />
              </div>
            </div>
          </div>
        ) : null}

        <div
          className="il--header-menu--mobile-button"
          style={{
            backgroundImage: `url(${imagesProvider.icons.hamburger.main})`,
          }}
          onClick={() => setIsExpanded(true)}
        ></div>
      </div>
    </>
  );
};

export default HeaderMenu;
