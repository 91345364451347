const DevelopmentMM = (t: any) => {
  return (
    <div>
      <p>{t("services.section12.content1.text1")}</p>
      <p>{t("services.section12.content1.text2")}</p>
      <p>{t("services.section12.content1.text3")}</p>
      <p>{t("services.section12.content1.text4")}</p>
    </div>
  );
};

export default DevelopmentMM;
