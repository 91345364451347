import BrandCodeContent from "../containers/pages/services/data/blockchainStartupDocuments/BrandCode";
import Tokenomics from "../containers/pages/services/data/blockchainStartupDocuments/Tokenomics";
import WhitePaper from "../containers/pages/services/data/blockchainStartupDocuments/WhitePaper";
import BusinessPlan from "../containers/pages/services/data/blockchainStartupDocuments/BusinessPlan";
import PitchDeck from "../containers/pages/services/data/blockchainStartupDocuments/PitchDeck";
import PrPlan from "../containers/pages/services/data/blockchainStartupDocuments/PrPlan";
import MarketingPlan from "../containers/pages/services/data/blockchainStartupDocuments/MarketingPlan";

import ListingPlanCEX from "../containers/pages/services/data/productDocs/ListingPlanCEX";
import ListingPlanDEX from "../containers/pages/services/data/productDocs/ListingPlanDEX";
import ListingPoolPlan from "../containers/pages/services/data/productDocs/ListingPoolPlan";
import RoadshowPlan from "../containers/pages/services/data/productDocs/RoadshowPlan";
import P2Egame from "../containers/pages/services/data/productDocs/P2Egame";

import BrandDesign from "../containers/pages/services/data/design/BrandDesign";
import ProductDesign from "../containers/pages/services/data/design/ProductDesign";
import WebDesign from "../containers/pages/services/data/design/WebDesign";

import LandingPage from "../containers/pages/services/data/tokenDevelopment/LandingPage";
import SmartContracts from "../containers/pages/services/data/tokenDevelopment/SmartContracts";
import MembershipArea from "../containers/pages/services/data/tokenDevelopment/MembershipArea";

import Roadshow from "../containers/pages/services/data/roadshowSMM/Roadshow";
import SMM from "../containers/pages/services/data/roadshowSMM/SMM";
import CommunityManagement from "../containers/pages/services/data/roadshowSMM/CommunityManagement";

import IdoIcoIeoSto from "../containers/pages/services/data/tokensale/IdoIcoIeoSto";
import B2Bsales from "../containers/pages/services/data/tokensale/B2Bsales";

import ListingCEX from "../containers/pages/services/data/goToMarketStrategy/ListingCEX";
import ListingDEX from "../containers/pages/services/data/goToMarketStrategy/ListingDEX";
import B2Bpartnerships from "../containers/pages/services/data/goToMarketStrategy/B2Bpartnerships";
import PrivateFundsRaising from "../containers/pages/services/data/goToMarketStrategy/PrivateFundsRaising";

import DevelopmentMM from "../containers/pages/services/data/MM/DevelopmentMM";

import { imagesProvider } from "../imagesProvider/imagesProvider";

export const getServicesList = (t: any) => [
  {
    categoryName: t("services.section1.title"),
    categoryIdentificator: "1",
    iconPackage: imagesProvider.icons.serviceBlockchainDocs,
    bg: imagesProvider.backgrounds.serviceBlockchainDocs,
    isVisible: true,
    serviceItems: [
      [
        {
          name: t("services.section1.boxTitle1"),
          briefText: t("services.section1.boxSubtitle1"),
          content: BrandCodeContent,
          identificator: "BrandCodeContent",
          amount: "$3000",
          isVisible: true,
        },
        {
          name: t("services.section1.boxTitle2"),
          briefText: t("services.section1.boxSubtitle2"),
          content: Tokenomics,
          identificator: "Tokenomics",
          amount: "$3000",
          isVisible: true,
        },
        {
          name: t("services.section1.boxTitle3"),
          briefText: t("services.section1.boxSubtitle3"),
          content: WhitePaper,
          amount: "$9000",
          identificator: "WhitePaper",
          isVisible: true,
        },
        {
          name: t("services.section1.boxTitle4"),
          briefText: t("services.section1.boxSubtitle4"),
          content: BusinessPlan,
          amount: "$6000",
          identificator: "BusinessPlan",
          isVisible: true,
        },
        {
          name: t("services.section1.boxTitle5"),
          briefText: t("services.section1.boxSubtitle5"),
          content: PitchDeck,
          amount: t("services.section1.price5"),
          identificator: "PitchDeck",
          isVisible: true,
        },
        {
          name: t("services.section1.boxTitle6"),
          briefText: t("services.section1.boxSubtitle6"),
          content: PrPlan,
          amount: t("services.section1.price6"),
          identificator: "PrPlan",
          isVisible: true,
        },
        {
          name: t("services.section1.boxTitle7"),
          briefText: t("services.section1.boxSubtitle7"),
          content: MarketingPlan,
          amount: "$3000",
          identificator: "MarketingPlan",
          isVisible: true,
        },
      ],
    ],
  },
  {
    categoryName: t("services.section2.title"),
    categoryIdentificator: "2",
    iconPackage: imagesProvider.icons.serviceProductDocs,
    bg: imagesProvider.backgrounds.serviceProductDocs,
    isVisible: true,
    serviceItems: [
      [
        {
          name: t("services.section2.boxTitle1"),
          briefText: t("services.section2.boxSubtitle1"),
          content: ListingPlanCEX,
          identificator: "ListingPlanCEX",
          isVisible: true,
        },
        {
          name: t("services.section2.boxTitle2"),
          briefText: t("services.section2.boxSubtitle2"),
          content: ListingPlanDEX,
          identificator: "ListingPlanDEX",
          isVisible: true,
        },
        {
          name: t("services.section2.boxTitle3"),
          briefText: t("services.section2.boxSubtitle3"),
          content: ListingPoolPlan,
          identificator: "ListingPoolPlan",
          isVisible: true,
        },
        {
          name: t("services.section2.boxTitle4"),
          briefText: t("services.section2.boxSubtitle4"),
          content: RoadshowPlan,
          amount: t("services.section2.price4"),
          identificator: "RoadshowPlan",
          isVisible: true,
        },
        {
          name: t("services.section2.boxTitle5"),
          briefText: t("services.section2.boxSubtitle5"),
          content: P2Egame,
          identificator: "P2Egame",
          isVisible: true,
        }
      ],
    ],
  },
  {
    categoryName: t("services.section3.title"),
    categoryIdentificator: "3",
    iconPackage: imagesProvider.icons.serviceAdvising,
    bg: imagesProvider.backgrounds.serviceAdvising,
    isVisible: true,
    serviceItems: [
      [
        {
          name: t("services.section3.boxTitle1"),
          briefText: t("services.section3.boxSubtitle1"),
          content: BrandDesign,
          identificator: "BrandDesign",
          isVisible: true,
        },
        {
          name: t("services.section3.boxTitle2"),
          briefText: t("services.section3.boxSubtitle2"),
          content: ProductDesign,
          identificator: "ProductDesign",
          isVisible: true,
        },
        {
          name: t("services.section3.boxTitle3"),
          briefText: t("services.section3.boxSubtitle3"),
          content: WebDesign,
          identificator: "WebDesign",
          isVisible: true,
        },
      ],
    ],
  },
  {
    categoryName: t("services.section4.title"),
    categoryIdentificator: "4",
    iconPackage: imagesProvider.icons.serviceListings,
    bg: imagesProvider.backgrounds.serviceListings,
    isVisible: true,
    serviceItems: [
      [
        {
          name: t("services.section4.boxTitle1"),
          briefText: t("services.section4.boxSubtitle1"),
          content: LandingPage,
          identificator: "LandingPage",
          isVisible: true,
        },
        {
          name: t("services.section4.boxTitle2"),
          briefText: t("services.section4.boxSubtitle2"),
          content: SmartContracts,
          identificator: "SmartContracts",
          isVisible: true,
        },
        {
          name: t("services.section4.boxTitle3"),
          briefText: t("services.section4.boxSubtitle3"),
          content: MembershipArea,
          identificator: "MembershipArea",
          isVisible: true,
        },
      ],
    ],
  },
  {
    categoryName: t("services.section5.title"),
    categoryIdentificator: "5",
    iconPackage: imagesProvider.icons.serviceBlockchain,
    bg: imagesProvider.backgrounds.serviceBlockchain,
    isVisible: true,
    serviceItems: [
      [
        {
          name: t("services.section5.boxTitle1"),
          briefText: t("services.section5.boxSubtitle1"),

          identificator: "",
          isVisible: true,
        },
        {
          name: t("services.section5.boxTitle2"),
          briefText: t("services.section5.boxSubtitle2"),

          identificator: "",
          isVisible: true,
        },
        {
          name: t("services.section5.boxTitle3"),
          briefText: t("services.section5.boxSubtitle3"),

          identificator: "",
          isVisible: true,
        },
        {
          name: t("services.section5.boxTitle4"),
          briefText: t("services.section5.boxSubtitle4"),

          identificator: "",
          isVisible: true,
        },
        {
          name: t("services.section5.boxTitle5"),
          briefText: t("services.section5.boxSubtitle5"),

          identificator: "",
          isVisible: true,
        },
      ],
    ],
  },
  {
    categoryName: t("services.section6.title"),
    categoryIdentificator: "6",
    iconPackage: imagesProvider.icons.servicePR2,
    bg: imagesProvider.backgrounds.servicePR2,
    isVisible: true,
    serviceItems: [
      [
        {
          name: t("services.section6.boxTitle1"),
          briefText: t("services.section6.boxSubtitle1"),

          identificator: "",
          isVisible: true,
        },
        {
          name: t("services.section6.boxTitle2"),
          briefText: t("services.section6.boxSubtitle2"),

          identificator: "",
          isVisible: true,
        },
        {
          name: t("services.section6.boxTitle3"),
          briefText: t("services.section6.boxSubtitle3"),

          identificator: "",
          isVisible: true,
        },
        {
          name: t("services.section6.boxTitle4"),
          briefText: t("services.section6.boxSubtitle4"),

          identificator: "",
          isVisible: true,
        },
        {
          name: t("services.section6.boxTitle5"),
          briefText: t("services.section6.boxSubtitle5"),

          identificator: "",
          isVisible: true,
        },
        {
          name: t("services.section6.boxTitle6"),
          briefText: t("services.section6.boxSubtitle6"),

          identificator: "",
          isVisible: true,
        },
        {
          name: t("services.section6.boxTitle7"),
          briefText: t("services.section6.boxSubtitle7"),

          identificator: "",
          isVisible: true,
        },
        {
          name: t("services.section6.boxTitle8"),
          briefText: t("services.section6.boxSubtitle8"),

          identificator: "",
          isVisible: true,
        },
        {
          name: t("services.section6.boxTitle9"),
          briefText: t("services.section6.boxSubtitle9"),

          identificator: "",
          isVisible: true,
        },
        {
          name: t("services.section6.boxTitle10"),
          briefText: t("services.section6.boxSubtitle10"),

          identificator: "",
          isVisible: true,
        },
        {
          name: t("services.section6.boxTitle11"),
          briefText: t("services.section6.boxSubtitle11"),

          identificator: "",
          isVisible: true,
        },
        {
          name: t("services.section6.boxTitle12"),
          briefText: t("services.section6.boxSubtitle12"),

          identificator: "",
          isVisible: true,
        },
        {
          name: t("services.section6.boxTitle13"),
          briefText: t("services.section6.boxSubtitle13"),

          identificator: "",
          isVisible: true,
        },
        {
          name: t("services.section6.boxTitle14"),
          briefText: t("services.section6.boxSubtitle14"),

          identificator: "",
          isVisible: true,
        },
      ],
    ],
  },
  {
    categoryName: t("services.section7.title"),
    categoryIdentificator: "7",
    iconPackage: imagesProvider.icons.servicePR,
    bg: imagesProvider.backgrounds.servicePR,
    isVisible: true,
    serviceItems: [
      [
        {
          name: t("services.section7.boxTitle1"),
          briefText: t("services.section7.boxSubtitle1"),

          identificator: "",
          isVisible: true,
        },
        {
          name: t("services.section7.boxTitle2"),
          briefText: t("services.section7.boxSubtitle2"),
          
          identificator: "",
          isVisible: true,
        },
        {
          name: t("services.section7.boxTitle3"),
          briefText: t("services.section7.boxSubtitle3"),

          identificator: "",
          isVisible: true,
        },
      ],
    ],
  },
  {
    categoryName: t("services.section8.title"),
    categoryIdentificator: "8",
    iconPackage: imagesProvider.icons.serviceSmm,
    bg: imagesProvider.backgrounds.serviceSmm,
    isVisible: true,
    serviceItems: [
      [
        {
          name: t("services.section8.boxTitle1"),
          briefText: t("services.section8.boxSubtitle1"),
          amount: "$5000",
          content: Roadshow,
          identificator: "Roadshow",
          isVisible: true,
        },
        {
          name: t("services.section8.boxTitle2"),
          briefText: t("services.section8.boxSubtitle2"),
          amount: "$6800",
          content: SMM,
          identificator: "SMM",
          isVisible: true,
        },
        {
          name: t("services.section8.boxTitle3"),
          briefText: t("services.section8.boxSubtitle3"),
          content: CommunityManagement,
          identificator: "CommunityManagement",
          isVisible: true,
        },
      ],
    ],
  },
  {
    categoryName: t("services.section9.title"),
    categoryIdentificator: "9",
    iconPackage: imagesProvider.icons.serviceLegal,
    bg: imagesProvider.backgrounds.serviceLegal,
    isVisible: true,
    serviceItems: [
      [
        {
          name: t("services.section9.boxTitle1"),
          briefText: t("services.section9.boxSubtitle1"),

          identificator: "",
          isVisible: true,
        },
        {
          name: t("services.section9.boxTitle2"),
          briefText: t("services.section9.boxSubtitle2"),

          identificator: "",
          isVisible: true,
        },
        {
          name: t("services.section9.boxTitle3"),
          briefText: t("services.section9.boxSubtitle3"),

          identificator: "",
          isVisible: true,
        },
        {
          name: t("services.section9.boxTitle4"),
          briefText: t("services.section9.boxSubtitle4"),

          identificator: "",
          isVisible: true,
        },
      ],
    ],
  },

  {
    categoryName: t("services.section10.title"),
    categoryIdentificator: "10",
    iconPackage: imagesProvider.icons.serviceTokensale,
    bg: imagesProvider.backgrounds.serviceTokensale,
    isVisible: true,
    serviceItems: [
      [
        {
          name: t("services.section10.boxTitle1"),
          briefText: t("services.section10.boxSubtitle1"),
          content: IdoIcoIeoSto,
          identificator: "IdoIcoIeoSto",
          isVisible: true,
        },
        {
          name: t("services.section10.boxTitle2"),
          briefText: t("services.section10.boxSubtitle2"),
          content: B2Bsales,
          identificator: "B2Bsales",
          isVisible: true,
        },
      ],
    ],
  },
  {
    categoryName: t("services.section11.title"),
    categoryIdentificator: "11",
    iconPackage: imagesProvider.icons.serviceMarketStrategy,
    bg: imagesProvider.backgrounds.serviceMarketStrategy,
    isVisible: true,
    serviceItems: [
      [
        {
          name: t("services.section11.boxTitle1"),
          briefText: t("services.section11.boxSubtitle1"),
          content: ListingCEX,
          identificator: "ListingCEX",
          isVisible: true,
        },
        {
          name: t("services.section11.boxTitle2"),
          briefText: t("services.section11.boxSubtitle2"),
          content: ListingDEX,
          identificator: "ListingDEX",
          isVisible: true,
        },
        {
          name: t("services.section11.boxTitle3"),
          briefText: t("services.section11.boxSubtitle3"),
          content: B2Bpartnerships,
          identificator: "B2Bpartnerships",
          isVisible: true,
        },
        {
          name: t("services.section11.boxTitle4"),
          briefText: t("services.section11.boxSubtitle4"),
          content: PrivateFundsRaising,
          identificator: "PrivateFundsRaising",
          isVisible: true,
        },
      ],
    ],
  },
  {
    categoryName: t("services.section12.title"),
    categoryIdentificator: "12",
    iconPackage: imagesProvider.icons.serviceMM,
    bg: imagesProvider.backgrounds.serviceMM,
    isVisible: true,
    serviceItems: [
      [
        {
          name: t("services.section12.boxTitle1"),
          briefText: t("services.section12.boxSubtitle1"),
          content: DevelopmentMM,
          identificator: "DevelopmentMM",
          isVisible: true,
        },
      ],
    ],
  },
];
