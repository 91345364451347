import "./Icon.scss";
import { IconStateEnum } from "../../imagesProvider/icons/icons";

interface IProps {
  width?: number;
  height?: number;
  alt?: string;
  iconPackage: any;
  iconState?: IconStateEnum;
}

const Icon = ({ width, height, alt, iconPackage, iconState }: IProps) => {
  const currentIconState = iconState || IconStateEnum.Main;

  const src = (iconPackage[currentIconState.toLowerCase()] ||
    iconPackage[IconStateEnum.Main.toLowerCase()]) as string;

  return (
    <img
      src={src}
      width={width || "100%"}
      height={height || "100%"}
      alt={alt || "icon"}
    />
  );
};

export default Icon;
