import "./Contacts.scss";
import "./Contacts.adaptive.scss";
import ContactsLink from "./link/ContactsLink";
import { PageEnum } from "../../../common/types";
import { useEffect, useState } from "react";
import { getContacts } from "../../../content/contacts";
import { useTranslation } from "react-i18next";

const Contacts = () => {
  const { t } = useTranslation();

  const [bitcoinPrice, setBitcoinPrice] = useState(0);
  const [interval, setiInterval] = useState<any>(null);

  useEffect(() => {
    const fetchIntervalMs = 30000;

    const fetchBTCPrice = () =>
      fetch("https://api.coindesk.com/v1/bpi/currentprice.json", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          setBitcoinPrice(data.bpi.USD.rate_float);
        })
        .catch((error) => {
          console.error("Currency Fetch Error:", error);
        });

    fetchBTCPrice();

    const newInterval = setInterval(fetchBTCPrice, fetchIntervalMs);

    setiInterval(newInterval);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div id={PageEnum.contacts} className="il--contacts">
      <div className="il--contacts--text">{t("contact.content1")}</div>
      <div className="il--contacts--amount">
        ${(Math.round(bitcoinPrice * 100) / 100).toFixed(2)}
      </div>
      <div className="il--contacts--text il--contacts--text-bottom">
        {t("contact.content2")}
      </div>
      {getContacts(t).map((contact, index) => (
        <div key={index} className="il--contacts--links">
          <ContactsLink
            title={contact.title}
            subTitle={contact.subTitle}
            link={contact.link}
            logo={contact.logo}
          />
        </div>
      ))}
    </div>
  );
};

export default Contacts;
