import "./ServicePage.scss";
import "./ServicePage.adaptive.scss";
import { ServiceData, ServiceItem } from "../shared/servicesTypes";
import ServicePageMenu from "./menu/ServicePageMenu";
import SliderButton from "../../../../components/sliderButton/SliderButton";
import ServicePageCard from "./card/ServicePageCard";
import Modal from "../../../../components/modal/Modal";
import { useEffect, useState } from "react";
import PrimaryButton from "../../../../components/primaryButton/PrimaryButton";
import { imagesProvider } from "../../../../imagesProvider/imagesProvider";
import { useTranslation } from "react-i18next";

interface IProps {
  servicesList: ServiceData[];
  currentServiceCategoryIdentificator: string;
  onChangePage: (serviceIdentificator: string) => void;
}

const ServicePage = ({
  servicesList,
  currentServiceCategoryIdentificator,
  onChangePage,
}: IProps) => {
  const [detailedServiceIdentificator, setDetailedServiceIdentificator] =
    useState<string | null>();

  const { t } = useTranslation();

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    const layout = document.getElementById("layout");

    if (layout) {
      layout.style.overflowY = "hidden";
    }

    return () => {
      document.body.style.overflowY = "scroll";
      const layout = document.getElementById("layout");

      if (layout) {
        layout.style.overflowY = "scroll";
      }
    };
  }, []);

  const serviceCategory = servicesList.find(
    (f) => f.categoryIdentificator === currentServiceCategoryIdentificator
  );

  const resolveNewServiceCategoryIdentificator = (
    direction: "prev" | "next"
  ): string => {
    const index = servicesList.findIndex(
      (service) =>
        service.categoryIdentificator === currentServiceCategoryIdentificator
    );

    if (index < 0) {
      return currentServiceCategoryIdentificator;
    }

    if (direction === "prev") {
      if (index === 0) {
        return servicesList[servicesList.length - 1].categoryIdentificator;
      }

      return servicesList[index - 1].categoryIdentificator;
    }

    if (direction === "next") {
      if (index === servicesList.length - 1) {
        return servicesList[0].categoryIdentificator;
      }

      return servicesList[index + 1].categoryIdentificator;
    }

    return currentServiceCategoryIdentificator;
  };

  const resolveServiceItemData = (
    serviceCategory: ServiceData
  ): ServiceItem => {
    let result: ServiceItem = {
      name: "",
      briefText: "",
      identificator: "",
      isVisible: true,
    };

    let services = serviceCategory.serviceItems.find((f) =>
      f.find((x) => x.identificator === detailedServiceIdentificator)
    );

    if (!services) {
      return result;
    }

    const service = services.find(
      (f) => f.identificator === detailedServiceIdentificator
    );

    if (!service) {
      return result;
    }

    return service;
  };

  const renderSubtitle = (amount?: string): JSX.Element => {
    return (
      <div className="il--service-page--modal-subtitle">
        <div className="il--service-page--modal-subtitle-amount">{amount}</div>
        <div className="il--service-page--modal-subtitle-nav">
          {/* <div className="il--service-page--modal-nav-button-wrapper">
            <PrimaryButton
              extendedWidth={true}
              title="Contact us"
              iconPackage={imagesProvider.icons.arrowRightDown}
            />
          </div> */}
          <div className="il--service-page--modal-nav-button-wrapper">
            <PrimaryButton
              onClick={() => setDetailedServiceIdentificator(null)}
              extendedWidth={true}
              title="Explore"
              iconPackage={imagesProvider.icons.arrowRightDown}
            />
          </div>
        </div>
      </div>
    );
  };

  const service = serviceCategory && resolveServiceItemData(serviceCategory);

  return (
    <>
      {service?.content && (
        <Modal
          title={service?.name}
          subtitle={renderSubtitle(service?.amount)}
          onClose={() => setDetailedServiceIdentificator(null)}
        >
          <div className="il--service-page-modal-content">
            <div className="il--service-page-modal-content--text">
              {service?.content(t)}
            </div>
          </div>
        </Modal>
      )}
      <div className="il--service-page">
        <div className="il--service-page--inner">
          <div className="il--service-page--title">
            {serviceCategory?.categoryName}
          </div>
          <div className="il--service-page--icon-wrapper">
            <img src={serviceCategory?.iconPackage.main} alt="icon" />
          </div>
          <div
            className="il--service-page--icon-wrapper-alt"
            style={{
              backgroundImage: `url(${serviceCategory?.bg})`,
            }}
          >
            {/*  <img width={'100%'} src={serviceCategory?.bg} alt='icon' /> */}
          </div>
          <div className="il--service-page--content-wrapper">
            <div className="il--service-page--nav-prev">
              <SliderButton
                position="left"
                title="Back"
                onClick={() =>
                  onChangePage(resolveNewServiceCategoryIdentificator("prev"))
                }
              />
            </div>
            <div className="il--service-page--content">
              {serviceCategory?.serviceItems.map((row, i) => (
                <div key={i} className="il--service-page--content-row">
                  {row
                    .filter((item) => item.isVisible)
                    .map((card, j) => (
                      <ServicePageCard
                        key={j}
                        identificator={card.identificator || ""}
                        title={card.name}
                        text={card.briefText}
                        onClick={(identificator) =>
                          setDetailedServiceIdentificator(identificator)
                        }
                      />
                    ))}
                </div>
              ))}
            </div>
            <div className="il--service-page--nav-next">
              <SliderButton
                position="right"
                title="Next"
                onClick={() =>
                  onChangePage(resolveNewServiceCategoryIdentificator("next"))
                }
              />
            </div>
          </div>
          <div className="il--service-page--adaptive-content-wrapper">
            <div className="il--service-page--nav-prev">
              <SliderButton
                position="left"
                title="Back"
                onClick={() =>
                  onChangePage(resolveNewServiceCategoryIdentificator("prev"))
                }
              />
            </div>
            <div className="il--service-page--adaptive-content">
              {serviceCategory?.serviceItems.map((row, i) =>
                row.map((card, j) => (
                  <ServicePageCard
                    key={`${i}_${j}`}
                    identificator={card.identificator || ""}
                    title={card.name}
                    text={card.briefText}
                    onClick={(identificator) =>
                      setDetailedServiceIdentificator(identificator)
                    }
                  />
                ))
              )}
            </div>
            <div className="il--service-page--nav-next">
              <SliderButton
                position="right"
                title="Next"
                onClick={() =>
                  onChangePage(resolveNewServiceCategoryIdentificator("next"))
                }
              />
            </div>
          </div>
          <div className="il--service-page--menu-wrapper">
            <ServicePageMenu
              servicesList={servicesList}
              onChangePage={onChangePage}
              currentServiceIdentificator={currentServiceCategoryIdentificator}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicePage;
