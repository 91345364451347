const ListingDEX = (t: any) => {
  return (
    <div>
      <p>{t("services.section11.content2.text1")}</p>
      <p>{t("services.section11.content2.text2")}</p>
      <p>{t("services.section11.content2.text3")}</p>
      <p>{t("services.section11.content2.text4")}</p>

      <p><b>{t("services.section11.content2.text5")}</b></p>

      <span>{t("services.section11.content2.text6")}</span>
      <ul>
        <li>{t("services.section11.content2.text7")}</li>
        <li>{t("services.section11.content2.text8")}</li>
        <li>{t("services.section11.content2.text9")}</li>
        <li>{t("services.section11.content2.text10")}</li>
        <li>{t("services.section11.content2.text11")}</li>
      </ul>

    </div>
  );
};

export default ListingDEX;
