import "./HeaderLogo.scss";
import "./HeaderLogo.adaptive.scss";
import { imagesProvider } from "../../../../imagesProvider/imagesProvider";
import { PageEnum } from "../../../../common/types";

interface IProps {
  changeActivePage_RD?: (
    activePageIdentificator: PageEnum,
    autoScrollAllowed?: boolean
  ) => void;
}

const HeaderLogo = ({ changeActivePage_RD }: IProps) => {
  const onClickHandler = (pageIdentificator: PageEnum): void => {
    if (changeActivePage_RD) {
      changeActivePage_RD(pageIdentificator, true);
    }
  };
  return (
    <div
      className="il--header-logo"
      onClick={() => onClickHandler(PageEnum.landing)}
    >
      <img src={imagesProvider.logo} alt="logo" />
    </div>
  );
};

export default HeaderLogo;
