const ProductDesign = (t: any) => {
  return (
    <div>
      <p>{t("services.section3.content2.text1")}</p>
      <p>{t("services.section3.content2.text2")}</p>
      <p>{t("services.section3.content2.text3")}</p>
    </div>
  );
};

export default ProductDesign;
